import React from 'react'
import imgCircular1 from '../../../assets/images/empresa/Ellipse 1.png'
import imgCircular2 from '../../../assets/images/empresa/Ellipse 2.png'
import imgCircular6 from '../../../assets/images/empresa/Ellipse 6.png'
const ImagenesEmpresa = (): any => {
  return (
    <div className="w-full flex justify-center relative mb-[450px]">
        <img className='z-100 absolute top-28 left-1/2 -translate-x-[calc(50%+215px)] object-cover' src={imgCircular1} alt="imagen circular 1"/>
        <img className='z-200 absolute top-28  left-1/2 -translate-x-1/2 object-cover' src={imgCircular2} alt="imagen circular 2"/>
        <img className='z-300 absolute top-28  left-1/2 -translate-x-[calc(50%-215px)] object-cover' src={imgCircular6} alt="imagen circular 6" />
      </div>
  )
}

export default ImagenesEmpresa
