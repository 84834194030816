/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import clsx from 'clsx'
import Text from '../textStyleDefault/texto'

interface BannerProps {
  image: string
  title: string
  subtitle?: string
  description?: string
  height?: string
  saltolinea?: boolean
}

const Banner: React.FC<BannerProps> = ({ image, title, subtitle, description, saltolinea, height = '363px' }) => {
  const hasSubtitle = Boolean(subtitle)
  const hasDescription = Boolean(description)

  return (
    <div
      className="relative flex justify-center items-center w-screen mt-[75px] md:mt-[104px] bg-cover bg-center"
      style={{
        backgroundImage: `url(${image})`,
        height
      }}
    >
      <div
        className={clsx(
          'flex flex-col justify-center items-center w-[993px] font-light p-4 bg-[rgba(206,214,224,0.4)]',
          {
            'text-center': !hasSubtitle
          })
        }
      >
        <Text variant='proyectosTitulo' className='max-w-[95%] inline-block whitespace-nowrap leading-[80px] font-light'>
          {title}
          {hasSubtitle && <Text as='span' className='leading-10 tracking-tight font-normal'>{subtitle}</Text>}
        </Text>
        {hasDescription && (
          <Text as='h6' className={clsx(
            'mt-4 w-[67%] leading-[25px] tracking-tight font-normal ',
            { 'text-center': !hasDescription })}>
            {description}
            {(saltolinea ?? false) && (
              <>
                <br />
                <br />
              </>
            )}
          </Text>
        )}
      </div>
    </div>
  )
}

export default Banner
