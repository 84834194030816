import flechaDerecha from '../../../assets/images/inicio/arrow-right.svg'
import React from 'react'
import Text from '../../shared/textStyleDefault/texto' // Importa el componente Text

interface BotonServicioProps {
  texto: string
}

const BotonServicio = (props: BotonServicioProps): any => {
  return (
    <button className="w-auto max-w-[280px] md:max-w-max h-[40px] md:w-fit  md:h-[60px] px-4 md:px-6 bg-blue-400 rounded-lg inline-flex justify-center items-center gap-[6px] md:gap-[10px] overflow-hidden whitespace-nowrap text-ellipsis">
      <Text variant='button' as='button' className='justify-start tracking-tight overflow-hidden  text-ellipsis pl-0 md:pl-4'>
        {props.texto}
      </Text>
      &nbsp;&nbsp;
      <img src={flechaDerecha} alt="inicio" />
    </button>
  )
}

export default BotonServicio
