import React from 'react'
import { NavLink } from 'react-router-dom'
import Text from '../textStyleDefault/texto'

interface BotonContactoMovilProps {
  texto: string
  onClick?: () => void
}

const BotonContactoMovil = ({ texto, onClick }: BotonContactoMovilProps): any => {
  return (
    <NavLink to="/contacto" onClick={onClick}>
      <Text
        as="button"
        className="text-[#043873] bg-[#FFE492] w-[123px] h-[40px] text-transfom: lowercase font-medium leading-none tracking-tight border-none text-[14px] rounded-[8px]"
      >
        {texto}
      </Text>
    </NavLink>
  )
}

export default BotonContactoMovil
