import React from 'react'
import { NavLink } from 'react-router-dom'
import Text from '../textStyleDefault/texto'

interface BotonContactoProps {
  texto: string
  onClick?: () => void
}

const BotonContacto = ({ texto, onClick }: BotonContactoProps): any => {
  return (
    <NavLink to="/contacto" onClick={onClick}>
      <Text
        as="button"
        className="text-[#043873] bg-[#FFE492] h-14 px-10 py-4 border-none rounded-[8px] flex items-center"
      >
        {texto}
      </Text>
    </NavLink>
  )
}

export default BotonContacto
