import { useTranslation } from 'react-i18next'
import React from 'react'
import Text from '../textStyleDefault/texto' // Aseg�rate de importar el componente correctamente

interface ContactoProps {
  color: string
  iconoMail: string
  iconoUbi: string
}

const Contacto = (props: ContactoProps): any => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col gap-[21px] text-start '>
      <Text as="p" className={'flex items-center  gap-[26.25]'}>
        <img decoding="async" src={props.iconoMail} alt="email" width="25" height="25" />
        <Text as="a" href="mailto:info@criterian.es" target="_blank" rel="noreferrer"
          className={'text-[13.99px] md:!text-[16.01px] pl-[26.25px] underline'} variant="body1">
          info@criterian.es
        </Text>
      </Text>
      <Text variant='subtitle1' className={'!text-[14px] md:!text-[16.01px] flex gap-[26.25px] items-center'}>
        <img decoding="async" src={props.iconoUbi} alt="maps" width="25" height="25" />
        {t('direccion')} Madrid 28045
      </Text>
    </div>
  )
}

export default Contacto
