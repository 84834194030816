import React from 'react'
import clsx from 'clsx'

interface TextProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'a' | 'button'
  variant?:
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'overline'
  | 'contacto'
  | 'porque'
  | 'porquetitulo'
  | 'casosTitulo'
  | 'casosSubtitulo'
  | 'proyectosTitulo'
  | 'button'

  children: React.ReactNode
  className?: string
  href?: string
  target?: string
  rel?: string
}

const Text = <T extends React.ElementType = 'p'>({
  as,
  children,
  className,
  variant,
  ...props
}: TextProps & React.ComponentPropsWithoutRef<T>): React.ReactElement => {
  const Tag = (as ?? 'p') as unknown as React.ElementType

  return (
    <Tag
      className={clsx(
        {
          'text-[102px] font-light': Tag === 'h1' && variant === undefined,
          'text-[36px] md:text-[64px] font-light': Tag === 'h2' && variant === undefined,
          'text-[24px] md:text-[52px] font-normal': Tag === 'h3' && variant === undefined,
          'text-[24px] md:text-[36px] font-normal': Tag === 'h4' && variant === undefined,
          'text-[16px] md:text-[24px] font-normal': Tag === 'h5' && variant === undefined,
          'text-[24px] md:text-[36px] font-light': Tag === 'span' && variant === undefined,
          'text-[14px] md:text-[20px] font-normal': Tag === 'h6' && variant === undefined,
          'text-[12px] md:text-[20px] font-normal': Tag === 'p' && variant === undefined,
          'text-[12px] md:text-[16px] font-normal': Tag === 'a' && variant === undefined,
          'text-sm md:text-base font-normal': variant === 'subtitle1',
          'text-[14px] font-medium': (variant !== undefined && ['subtitle2', 'button'].includes(variant)) || variant === 'casosTitulo',
          'text-[16px] font-medium': variant === 'body1',
          'text-[14px] font-normal': variant === 'body2',
          'text-[8px] font-normal': variant === 'overline',
          'text-[20px] md:text-[52px] font-normal': variant === 'contacto',
          'text-[8px] md:text-base': variant === 'porque',
          'text-[14px] md:text-[24px]': variant === 'porquetitulo',
          'text-[16px] md:text-[20px]': variant === 'casosSubtitulo',
          'text-[24px] md:text-[64px]': variant === 'proyectosTitulo'
        },
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  )
}

export default Text
