import consultoria from '../../assets/images/inicio/image-consultoria.png'
import desarrollo from '../../assets/images/inicio/image-desarrollo-software.png'
import gestion from '../../assets/images/inicio/image-gestion-productos.png'
import soporte from '../../assets/images/inicio/image-soporte-operaciones.png'
import arquitectura from '../../assets/images/inicio/image-arquitectura.png'
import estrategia from '../../assets/images/inicio/image-estrategia.png'
import adaptabilidad from '../../assets/images/inicio/icon-adaptabilidad.svg'
import apoyo from '../../assets/images/inicio/icon-apoyo.svg'
import experiencia from '../../assets/images/inicio/icon-experiencia.svg'
import soluciones from '../../assets/images/inicio/icon-soluciones.svg'

import logoTifin from '../../assets/images/casosExito/logo-tifin.svg'
import logoEntrenaVirtual from '../../assets/images/casosExito/logo-entrena-virtual.svg'
import logoVikika from '../../assets/images/casosExito/logo-vikika.svg'
import logoRFHE from '../../assets/images/casosExito/logo-rfhe.svg'
import logoArbor from '../../assets/images/casosExito/logo-arbor.svg'
import logoSavso from '../../assets/images/casosExito/logo-savso.svg'
import logoGaia from '../../assets/images/casosExito/logo-gaia.svg'
import logoPella from '../../assets/images/casosExito/logo-pella.svg'
import logoAxis from '../../assets/images/casosExito/logo-axispharma.svg'

// import imagenArbor from '../../assets/images/casosExito/mockup_arbor.png'
// import imagenRFHE from '../../assets/images/casosExito/mockup_rfhe.png'
// import imagenEntrenaVirtual from '../../assets/images/casosExito/mockup_entrena.png'
// import imagenSavso from '../../assets/images/casosExito/mockup_savso.png'
// import logoFUE from '../../assets/images/casosExito/logo-fue.png'
// import imagenGaia from '../../assets/images/casosExito/mockup_gaia.png'
// import imagenPella from '../../assets/images/casosExito/mockup_pella-2.png'
// import imagenAxis from '../../assets/images/casosExito/mockup_axispharma.png'

// import googlePlayImagen from '../../assets/images/casosExito/googleplay.png'
// import appleStoreImagen from '../../assets/images/casosExito/appstore.png'

import alineacion from '../../assets/images/desarrollo/icon-alineacion.svg'
import eficiencia from '../../assets/images/desarrollo/icon-eficiencia.svg'
import resiliencia from '../../assets/images/desarrollo/icon-resiliencia.svg'
import sostenible from '../../assets/images/desarrollo/icon-sostenible.svg'

const razones =
[
  {
    icono: soluciones,
    titulo: 'Soluciones Prácticas',
    texto: 'Ofrecemos soluciones que se adaptan a tu negocio, priorizando la practicidad para simplificar tus operaciones diarias.'
  },
  {
    icono: experiencia,
    titulo: 'Experiencia Confiable',
    texto: 'Con [número] años de experiencia, hemos aprendido lo que realmente importa. Nuestra trayectoria habla por sí misma.'
  },
  {
    icono: adaptabilidad,
    titulo: 'Adaptabilidad a tu medida',
    texto: 'Creemos en la flexibilidad. Nuestro software se adapta a tus necesidades, no al revés. Personalización real para empresas reales.'
  },
  {
    icono: apoyo,
    titulo: 'Apoyo Genuino',
    texto: 'Nuestro compromiso va más allá del software. Estamos aquí para brindarte un apoyo genuino en cada paso, porque tu éxito es el nuestro.'
  }
]
const servicios =
[
  {
    imagen: consultoria,
    titulo: 'Consultoría',
    texto: 'Navegamos justos hacia soluciones empresariales.',
    textoBoton: 'Explora soluciones estratégicas'
  },
  {
    imagen: desarrollo,
    titulo: 'Desarrollo de Software',
    texto: 'Codigo creativo, soluciones sorprendentes',
    textoBoton: 'Haz realidad tus ideas'
  },
  {
    imagen: gestion,
    titulo: 'Gestión de productos',
    texto: 'De la idea al éxito: gestionamos, optimizamos, triunfamos',
    textoBoton: 'Potencia tus productos'
  },
  {
    imagen: soporte,
    titulo: 'Soporte de operaciones',
    texto: 'Operaciones fluyendo sin obstáculos, siempre',
    textoBoton: 'Optimizar operaciones'
  },
  {
    imagen: arquitectura,
    titulo: 'Arquitectura de Software',
    texto: 'Construyendo bases sólidas',
    textoBoton: 'Descubre nuestras soluciones de arquitectura'
  },
  {
    imagen: estrategia,
    titulo: 'Estrategia TI',
    texto: 'Construyendo estrategias digitales para el futuro',
    textoBoton: 'Descubre tu estrategia TI'
  }
]

const casosexito =
[
  {
    logos: [logoTifin],
    nombre: 'Tifin',
    urls: ['https://tifin.com/'],
    texto: 'TIFIN es una empresa estadounidense especializada en bienestar financiero. Desarrollamos su plataforma TIFIN @Work, una solución conversacional impulsada por inteligencia artificial que brinda asesoramiento financiero personalizado a empleados. Seguimos colaborando en este y otros proyectos para mejorar la accesibilidad y eficiencia de sus servicios.'
  },
  {
    logos: [logoEntrenaVirtual],
    nombre: 'Entrena Virtual',
    urls: ['https://www.entrenavirtual.es/app'],
    texto: 'Criterian brindó consultoría estratégica a Entrenavirtual, enfocándose en la gestión interna y la organización del equipo. Nuestro análisis detallado les ayudó a optimizar su estructura y definir un camino sólido en la industria del bienestar.'
  },
  {
    logos: [logoVikika],
    nombre: 'Vikika Team',
    urls: ['https://vikika.es/'],
    texto: 'Vikika Team, liderado por Verónica Costa, ofrece planes de nutrición y entrenamiento. Nos encargamos del rediseño de su web y la optimización de su funcionalidad interna para mejorar la experiencia del usuario.'
  },
  {
    logos: [logoRFHE],
    nombre: 'Real Federación Hípica Española',
    urls: ['https://www.rfhe.com'],
    texto: 'Lideramos el desarrollo de la aplicación y la mejora de la accesibilidad web de la Real Federación Hípica Española (RFHE), creando una experiencia más inclusiva para personas con diversidad funcional.'
  },
  {
    logos: [logoArbor],
    nombre: 'Arbor',
    urls: ['https://www.arborfintech.com'],
    texto: 'En Criterian, desarrollamos la presencia online y la app móvil de Arbor, materializando su visión de salud financiera. Creamos una plataforma que ofrece educación financiera, ahorro, inversión y préstamos transparentes.'
  },
  {
    logos: [logoSavso],
    nombre: 'Savso',
    urls: ['https://www.savso.es'],
    texto: 'Savso es una empresa especializada en minicréditos y préstamos rápidos en línea, con montos entre 100€ y 900€ y devoluciones semanales. Desarrollamos una plataforma integral que incluye el portal del cliente y el back-office, optimizando la gestión de sus servicios financieros.'
  },
  {
    logos: [logoGaia],
    nombre: 'Gaia Program',
    urls: ['https://www.gaianext.com', 'https://www.bridgenext.es'],
    texto: 'Gaia es una empresa dedicada a la oferta de másteres online. Colaboramos en el desarrollo del software para su plataforma educativa, facilitando una experiencia de aprendizaje en línea óptima y accesible para sus estudiantes.'
  },
  {
    logos: [logoPella],
    nombre: 'Pella',
    urls: ['https://pellaathletika.com/'],
    texto: 'Pella es una aplicación enfocada en el entrenamiento físico. Participamos en su desarrollo, creando una herramienta intuitiva y funcional que permite a los usuarios planificar y seguir sus rutinas de ejercicio de manera efectiva'
  },
  {
    logos: [logoAxis],
    nombre: 'Axis Pharma',
    urls: ['https://es.axispharma.com/'],
    texto: 'Axis Pharma es una consultora estratégica líder en el sector farmacéutico y biotecnológico, con más de 20 años de experiencia. Desarrollamos una plataforma que optimiza la recolección y gestión de datos clave para sus estudios médicos, mejorando la eficiencia y facilitando la toma de decisiones estratégicas.'
  }
]

const valores =
[
  {
    titulo: 'Colaboración',
    texto: 'Trabajamos en conjunto hacia metas comunes, reconociendo que el éxito individual de cada miembro contribuye al éxito colectivo de Criterian.'
  },
  {
    titulo: 'Integridad',
    texto: 'Actuamos con integridad y decimos lo que pensamos. Nos comprometemos a ser honestos y transparentes en todas nuestras interacciones, creando una base sólida para relaciones personales y profesionales.'
  },
  {
    titulo: 'Apertura',
    texto: 'Nos mantenemos abiertos a nuevas ideas y perspectivas, alentando la diversidad de opiniones y no temiendo el desacuerdo mientras buscamos alcanzar un consenso.'
  },
  {
    titulo: 'Responsabilidad',
    texto: 'Asumimos la responsabilidad de nuestras decisiones y de las consecuencias que puedan derivarse de ellas, construyendo una cultura de confianza y confiabilidad.'
  },
  {
    titulo: 'Excelencia',
    texto: 'Seguimos estándares de calidad excepcionales en todo lo que hacemos y nos esforzamos por la mejora continua, impulsados por el deseo de superar las expectativas.'
  },
  {
    titulo: 'Respeto',
    texto: 'Escuchamos a nuestros colegas como profesionales, fomentando un ambiente donde se respeta la diversidad y se valora a cada individuo como ser humano.'
  }
]
const nuestroTrabajo =
[
  {
    titulo: 'Consulta Personalizada',
    texto: 'Nos sumergimos en una consulta personalizada, sentándonos contigo para comprender en profundidad tus objetivos empresariales y desafíos únicos. Este diálogo nos permite establecer una base sólida para el trabajo conjunto.'
  },
  {
    titulo: 'Análisis Detallado',
    texto: 'Realizamos un análisis exhaustivo de tu infraestructura actual, utilizando herramientas avanzadas y expertise técnico. Este proceso nos permite identificar oportunidades de mejora y proponer soluciones personalizadas.'
  },
  {
    titulo: 'Planificación Estratégica',
    texto: 'Basándonos en los resultados del análisis, desarrollamos una planificación estratégica detallada. Este plan está meticulosamente adaptado a tus necesidades específicas, asegurando una hoja de ruta clara y efectiva para alcanzar tus metas.'
  },
  {
    titulo: 'Implementación',
    texto: 'Pasamos de la planificación a la acción, implementando la estrategia con eficiencia y precisión. Nuestro enfoque está respaldado por profesionales altamente capacitados y herramientas tecnológicas de vanguardia para garantizar resultados tangibles y duraderos.'
  },
  {
    titulo: 'Seguimiento y ajustes continuos',
    texto: 'Después de la implementación, no dejamos nada al azar. Realizamos evaluaciones periódicas para medir el rendimiento y realizar ajustes continuos según sea necesario. Este ciclo de mejora constante garantiza que nuestra colaboración evolucione con tu empresa.'
  }
]
const descripcionBeneficios =
[
  {
    titulo: '',
    texto: 'Nuestra Estrategia TI se enfoca en comprender tus objetivos empresariales y alinearlos con soluciones tecnológicas efectivas. A través de un análisis exhaustivo, identificamos oportunidades de mejora, optimización de recursos y fortalecimiento de la infraestructura tecnológica.'
  }
]
const beneficios =
[
  {
    icono: alineacion,
    titulo: 'Alineación con objetivos',
    texto: 'Integración de tecnología para potenciar tus metas comerciales.'
  },
  {
    icono: eficiencia,
    titulo: 'Eficiencia Operativa',
    texto: 'Optimización de procesos y recursos digitales.'
  },
  {
    icono: resiliencia,
    titulo: 'Resiliencia Tecnológica',
    texto: 'Preparación para los desafíos futuros del mundo digital.'
  },
  {
    icono: sostenible,
    titulo: 'Innovación Sostenible',
    texto: 'Impulso constante hacia nuevas oportunidades tecnológicas.'
  }
]
export default { servicios, razones, valores, nuestroTrabajo, descripcionBeneficios, beneficios, casosexito }
