import React from 'react'
import ServiciosInicio from './servicios-inicio'
import ContactoBanner from '../../shared/contactBanner/contacto-banner'
import RazonesCriterian from './razones-criterian'
import GifInicio from './gif-inicio'
const Inicio = (): any => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center'>
      <GifInicio/>
      <RazonesCriterian />
      <ServiciosInicio />
      <ContactoBanner/>
    </div>
  )
}
export default Inicio
