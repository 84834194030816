import React from 'react'
import Text from '../textStyleDefault/texto'
interface Valor {
  titulo: string
  texto: string
}
type listas = Valor[]
interface ListaDescripcionesProps {
  items: listas
}
const ListaDescripciones = (props: ListaDescripcionesProps): any => {
  return (
    <div className='h-full w-1/2'>
        {props.items.map((valor) => (
          <div key={valor.texto} className="w-full flex flex-col text-start gap-[16px] mb-10">
            <Text as='h5' className="mb-1 font-bold">{valor.titulo}</Text>
            <Text as='p' variant='subtitle1' className=' tracking-[0.024px] mr-20'>{valor.texto}</Text>
          </div>
        ))}
    </div>
  )
}
export default ListaDescripciones
