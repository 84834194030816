import React from 'react'
import techologies from '../../../assets/images/empresa/technologies.png'
import Text from '../../shared/textStyleDefault/texto'
import UnderlinedTitle from '../../shared/underlinedText/underlined-title'
const NuestraTecnologia = (): any => {
  return (
    <div className='w-[89%] h-[480px] flex justify-center items-center  overflow-hidden'>
        <div className = 'w-[45%] h-auto text-start'>
          <UnderlinedTitle texto='Nuestra tecnología' palabrasSubrayadas='tecnología' blanco={false} marginTop='none' width='500px' marginLeft={false} />
          <br /> <br />
          <Text variant='subtitle1' className=' max-w-[100%]  tracking-[0.024px]  font-normal leading-[20px]'>En Criterian, adoptamos el mantra de utilizar la tecnología adecuada para cada desafío. Nuestra agilidad va más allá de solo aprender nuevas herramientas y procesos; se trata de una profunda comprensión y adaptación continua. <br /><br /> Desde la elección meticulosa de lenguajes y frameworks hasta la implementación experta de arquitecturas escalables, cada decisión tecnológica está arraigada en la excelencia,con el objetivo de optimizar el rendimiento, reducir la complejidad y trazar un camino fluido hacia la solución de problemas en el mundo digital en constante cambio.</Text>
        </div>
        <img src={techologies} className='p-0' alt="tecnologías de desarrollo" />
    </div>
  )
}

export default NuestraTecnologia
