/* eslint-disable react/react-in-jsx-scope */
import logoCriterian from '../../../assets/images/icons/cropped-criterian-icon-180x180.png'
import { useLocation } from 'react-router-dom'
import SocialMedia from './social_media'
import Contacto from './contacto'
import FooterOptions from './footer_options'
import mapsIcon from '../../../assets/images/footer/location_on.svg'
import mailIcon from '../../../assets/images/footer/email.svg'
import Text from '../textStyleDefault/texto'

const Footer = (): any => {
  const location = useLocation()
  return (

    <div className="bg-white mt-28 w-full" style={{ display: location.pathname === '/politica-de-cookies' ? 'none' : 'block' }}>
      <div className="min-h-[543px] md:w-[1205px] mx-auto  md:border-t-[2px] border-[#043873] flex flex-col md:flex-row md:justify-between md:ml-[180px] w-[350px] border-t-[0.65px]">
        <div className=" md:w-[338px] flex justify-start mt-[35px] ml-[160px] md:mt-[132px] md:ml-[138px]">
          <a href="/" title="Criterian"><img src={logoCriterian} alt="inicio" className=" w-[45px] h-[45px] md:w-[60px] md:h-[60px]" /></a>
        </div>
        <div className="hidden md:block ml-[-135px] ">
          <FooterOptions />
        </div>
        <div className="text-left flex flex-col mt-[35px] md:space-y-[66px] md:mt-[52.5px]">
          <div className='font-medium w-[400px]'>
            <Contacto color="#1E1E1E" iconoMail={mailIcon} iconoUbi={mapsIcon} />
          </div>
          <div className="block  md:hidden ">
          <FooterOptions />
          </div>
          <div className='mt-[60px] md:mt-[66px]'>

            <SocialMedia />
          </div>
          <div className='text-center mt-[35px] md:justify-items-end'>
            <Text variant='subtitle2' className='!text-[12px] md:!text-[14px]  text-gray-500 text-'>© 2023 • Criterian.</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
