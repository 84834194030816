/* eslint-disable react/react-in-jsx-scope */
import ContactoBanner from '../../shared/contactBanner/contacto-banner'
import Descripciones from '../../shared/descriptions/Descripciones'
import constantes from '../../constantes/constantes'
import Beneficios from './beneficios'
import Banner from '../../shared/frontBanner/banner'
import fondoBanner from '../../../assets/images/desarrollo/banner_image.png'
const servicios = constantes.nuestroTrabajo
const descripcionBeneficios = constantes.descripcionBeneficios
const Desarrollo = ({ idioma }: any): any => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center'>
      <Banner image={fondoBanner} title='Estrategia TI ¡' subtitle='DISEÑANDO EL FUTURO DIGITAL!' saltolinea={ true } description='En el mundo tecnológico en constante evolución, la correcta gestión de la infraestructura y los recursos digitales es esencial. Nuestro servicio de Estrategia TI está diseñado para guiar a tu empresa hacia el éxito en el entorno digital.'/>
      <div className=' inline-flex flex-col justify-start items-start gap-14 mb-32 mt-24'>
       <Descripciones items={descripcionBeneficios} titulo='Beneficios' subrayado='Beneficios' blanco={false} />
       <Beneficios/>
      </div>
      <div className='mb-32'>
       <Descripciones items={servicios} titulo='Como trabajamos' subrayado='trabajamos' blanco={false} />
      </div>
      <ContactoBanner/>
    </div>
  )
}
export default Desarrollo
