import React from 'react'
import constantes from '../../constantes/constantes'
import UnderlinedTitle from '../../shared/underlinedText/underlined-title'
import Text from '../../shared/textStyleDefault/texto'

const razones = constantes.razones

const RazonesCriterian = (): any => {
  return (
    <div className='w-screen bg-[#043873]'>
      <div className="inline-flex flex-col items-start   md:py-8">
        <div className='mb-[34px] w-full'>
          <UnderlinedTitle blanco={true} texto='¿Por qué Criterian?' palabrasSubrayadas='Criterian' width='993.70px' marginTop='3%' marginLeft={false}/>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-x-[21px] md:gap-x-[40px] gap-y-[40px] w-full max-w-[1250px] mx-auto mb-16 px-4 md:px-0">
          {razones.map((razon) => (
            <div key={razon.icono} className="flex flex-col items-center gap-[10.6px] md:gap-[20px] text-center  md:mt-[65px] mb-3">
              <div>
                <img src={razon.icono} alt="icono" className=" gap-[10px] size-[33px] md:size-[64px] mb-[2px] md:mb-[24px]" />
              </div>
              <Text variant='porquetitulo' className="self-stretch text-center justify-start text-white text-xs   font-['Lato'] leading-[15px] tracking-tight">{razon.titulo}</Text>
              <Text variant='porque' className='  self-stretch text-center  justify-start text-white font-normal leading-[10px] md:leading-[20px] tracking-[0.12px] md:tracking-[0.024px]'>{razon.texto}</Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default RazonesCriterian
