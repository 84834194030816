import React from 'react'
import NavbarLinks from '../header/navbar-links'
const FooterOptions = (): any => {
  return (
      <div className='w-48 mt-[35px] md:mt-[40.99px]'>
            <div>
              <NavbarLinks color='#1E1E1E' variant="footer" />
            </div>
        </div>
  )
}
export default FooterOptions
