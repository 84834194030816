import React from 'react'
import group from '..//..//..//assets/images/empresa/Group.png'
import Text from '../../shared/textStyleDefault/texto'
const FraseEmpresa = (): any => {
  return (
    <div className='relative w-full h-[430px] bg-[#043873] flex items-center justify-center overflow-hidden my-16'>
        <img className = 'absolute -translate-y-[15%] left-0 z-[1] opacity-30' src={group} alt="side design" />
         <Text as='h4' className='text-white mx-[15%] font-normal md:tracking-[125%] md:leading-10'>&ldquo;En el equipo, como en la tecnología, la sinergia impulsa la innovación. Como Bill Gates dijo una vez: &lsquo;
          En este negocio, por cada persona increíble, debes tener una docena de personas increíbles trabajando juntas&rsquo;.
          Así es como creamos la magia del éxito, con un equipo que sobresale en cada línea de código.&ldquo;
          </Text>
       </div>
  )
}

export default FraseEmpresa
