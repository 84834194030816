/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import linkImg from '../../../assets/images/casosExito/link.png'
import Text from '../../shared/textStyleDefault/texto'

const Caso = ({ data }: any): any => {
  const { t } = useTranslation()
  const alt = `logo ${data.nombre}`
  const logos = (data.logos).map((logo: any) => (
    <img key={logo} src={logo} alt={alt} className='w-full h-auto' />
  ))

  return (
    <div className='flex flex-col justify-center text-center shadow-[0_0_0_0_rgba(71,74,89,0.20),0_0_0_0_rgba(71,74,89,0.20)] md:shadow-[0_2px_8px_0_rgba(71,74,89,0.20),0_2px_2px_0_rgba(71,74,89,0.20)] rounded-[20px] px-[30px] pt-[30px] pb-[6px] h-[380px] max-width-[590px] min-width-[387px]'>
      <div>
        <div className='flex flex-col justify-center items-center h-[60px] w-[60px] mx-auto mb-[12px]'>
          {logos}
        </div>
        <Text variant='casosTitulo' className='text-center justify-center text-sky-900 font-medium leading-none tracking-tight uppercase mb-[24px]'>
          {data.nombre}
        </Text>
        <div>
          <Text variant='casosSubtitulo' className='self-stretch text-center justify-start font-normal leading-[125%] tracking-[0.03px]'>
            {t(`${data.texto}`)}
          </Text>
        </div>
      </div>
      <div className='mt-auto'>
        <a href={data.urls[0]} target="_blank" rel="noreferrer">
          <img src={linkImg} alt="inicio" className='w-[32px] h-[32px] m-auto' />
        </a>
      </div>
    </div>
  )
}

export default Caso
