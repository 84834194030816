import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import Text from '../textStyleDefault/texto'
interface NavbarLinksProps {
  color: string
  variant?: 'header' | 'footer' | 'menu'
  onClick?: () => void
}
const NavbarLinks = (props: NavbarLinksProps): any => {
  const { t } = useTranslation()
  return (
    <ul style={{ color: props.color }} className={clsx('flex', { 'flex-row space-x-[32px] justify-center w-48 mx-auto ': props.variant === 'header', ' items-start flex-col space-y-[16px] right-[10%]  ': props.variant === 'footer', 'items-center flex-col w-max space-y-[32px] right-[10%] ': props.variant === 'menu' })}>
     <li className={clsx('group', props.variant === 'header' && ' flex justify-center text-[20px]', props.variant === 'footer' && 'text-[16px] md:text-[20px] relative flex flex-col ', props.variant === 'menu' && 'relative flex flex-col items-center')}>
        <NavLink to='productos-y-servicios/' className={ 'flex items-center  cursor-pointer' } onClick={props.onClick}>
          <Text as='h6'className={clsx('block', props.variant === 'footer' && 'text-[16px] md:text-[20px]', props.variant === 'menu' && 'text-[20px]')}>{t('servicios')}</Text>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='group-hover:rotate-180' fill={props.color}>
            <path d="M7.41 8.295L12 12.875L16.59 8.295L18 9.705L12 15.705L6 9.705L7.41 8.295Z"/>
          </svg>
        </NavLink>
        <ul className={clsx(' font-normal cursor-pointer',
          props.variant === 'header' && 'w-max  absolute p-[10px] text-left mt-[33.5px] rounded-[8px] border-white border-[1px] ml-[145px] space-y-[4px] hidden group-hover:block',
          props.variant === 'footer' && ' w-max  text-[14px] pt-[16px] space-y-[16px] text-left hidden group-hover:block',
          props.variant === 'menu' && ' w-[340px]  mx-[-37.62%]   pt-[24px] border-t-[1px]  border-white text-center mt-2 hidden group-hover:block',
          props.color === 'white' ? 'bg-[#043873]' : 'bg-white'
        )}>
          <li className={clsx('', props.variant === 'menu' && 'hover:bg-blue-500 ', props.variant === 'footer' && 'text-[16px] md:text-[20px]')}><NavLink to='productos-y-servicios/'><Text as='a' className={clsx(props.variant === 'menu' || props.variant === 'header' ? 'text-[12px]' : 'text-[14px]')}>{t('desarrollo de software')}</Text></NavLink></li>
          <li className={clsx('', props.variant === 'menu' && 'hover:bg-blue-500 ')}><Text as='a' href='https://due-diligence.criterian.es/' className={clsx(`text-${props.color}`, props.variant === 'menu' || props.variant === 'header' ? 'text-[12px]' : 'text-[14px]')} target='_blank' rel='noreferrer'>{t('due')}</Text></li>

        </ul>
      </li>
      <li className={clsx('block', props.variant === 'footer' && 'text-[16px] md:text-[20px]', props.variant === 'header' && 'text-[20px]')}><NavLink to='casos-de-exito/' className='flex'onClick={props.onClick}>{t('proyectos')}</NavLink></li>
      <li className={clsx('block', props.variant === 'footer' && 'text-[16px] md:text-[20px]', props.variant === 'header' && 'text-[20px]')}><NavLink to='la-empresa/' className='flex' onClick={props.onClick}>{t('nosotros')}</NavLink></li>
    </ul>
  )
}
export default NavbarLinks
