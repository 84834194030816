import React, { useState, useEffect } from 'react'
import Caso from './caso'
import constantes from '../../constantes/constantes'

const casosexito = constantes.casosexito

const CasosLista = (): any => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === casosexito.length - 1 ? 0 : prevIndex + 1
      )
    }, 3000)

    return () => { clearInterval(interval) }
  }, [])

  return (
    <div className="py-[8%]">
      <div className="relative w-[392px] sm:hidden overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`
          }}
        >
          {casosexito.map((data, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <Caso data={data} />
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center mt-[22px] sm:hidden">
        {casosexito.map((_, index) => (
          <button
            key={index}
            onClick={() => { setCurrentIndex(index) }}
            className={`w-3 h-3 mx-1 rounded-full transition-colors duration-300 ${
              index === currentIndex ? 'bg-[#043873]' : 'bg-[#4F9CF9]'
            }`}
          />
        ))}
      </div>

      <div className="hidden sm:grid sm:grid-cols-2 gap-[20px] w-full max-w-[1200px] mx-auto">
        {casosexito.map((data, index) => (
          <Caso key={index} data={data} />
        ))}
      </div>
    </div>
  )
}

export default CasosLista
