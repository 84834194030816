import React from 'react'
import Contacto from '../footer/contacto'
import mapsIcon from '../../../assets/images/footer/location_on-white.svg'
import mailIcon from '../../../assets/images/footer/email-white.svg'
import BotonContacto from '../buttons/boton-contacto'
import UnderlinedTitle from '../underlinedText/underlined-title'
import Text from '../textStyleDefault/texto'

const ContactoBanner = (): any => {
  return (
  <div className="text-[white] bg-[#043873] h-auto flex flex-col relative text-left w-screen ">
  <div className="absolute w-full h-full bg-[url('/src/assets/images/inicio/Element.png')] bg-no-repeat md:bg-center bg-cover" />
  <div className="h-[50%] flex flex-col justify-around text-left relative z-10 mb-[5%] px-4 lg:px-0 w-full max-w-[1200px] mx-auto">
  <UnderlinedTitle blanco={true} texto='¿Preparado para llevar tu proyecto al siguiente nivel?' palabrasSubrayadas='siguiente nivel' width='738.69px' marginTop='5%' marginLeft={false} />
    <Text as='h6' className=' max-w-[85%] md:max-w-[57.245%] mt-8 mb-6 md:mb-16 md:ml-16'>Te invitamos a explorar las oportunidades de transformar tu proyecto. Ponte en contacto con nosotros y descubre el camino hacia el éxito tecnológico</Text>
    <div className='md:ml-16'>
    <Contacto color='white' iconoMail={mailIcon} iconoUbi={mapsIcon} />
    <div className='mt-10 ml-20 md:ml-0 mb-16 md:mb-8'>
    <BotonContacto texto='CONTACTO'/>
    </div>
    </div>
  </div>
</div>
  )
}
export default ContactoBanner
