import React from 'react'
import BotonContacto from './boton-contacto'
import BotonContactoMovil from './boton-contacto-movil'

interface BotonContactoResponsiveProps {
  texto: string
  onClick?: () => void
}

const BotonContactoResponsive: any = ({ texto, onClick }: BotonContactoResponsiveProps) => {
  return (
    <div>
      {/* Botón para pantallas grandes */}
      <div className="hidden md:block">
        <BotonContacto texto={texto} onClick={onClick}/>
      </div>

      {/* Botón para pantallas pequeñas */}
      <div className="block md:hidden">
        <BotonContactoMovil texto={texto} onClick={onClick}/>
      </div>
    </div>
  )
}

export default BotonContactoResponsive
