/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import logo from './../../../assets/images/header/criterian_transparencia_1.svg'
import language from './../../../assets/images/header/language.svg'
import menu from './../../../assets/images/header/menu.svg'
import BotonContacto from '../buttons/boton-contacto'
import NavbarLinks from './navbar-links'
import BotonContactoResponsive from '../buttons/boton-contacto-responsive'

const Header = ({ cambiarIdioma }: any): any => {
  const { i18n } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [isSpanish, setIsSpanish] = useState(true)
  const onChangeLang = async (langCode: string): Promise<void> => {
    try {
      await i18n.changeLanguage(langCode)
      cambiarIdioma(langCode)
      setIsSpanish(langCode === 'es')
    } catch (error) {
      console.error('Error changing language:', error)
    }
  }

  if (location.pathname === '/politica-de-cookies') return null

  return (
    <div className="relative">
      {/* Navbar principal */}
      <div className="fixed w-full max-w-[1440px] mx-50% top-0 z-50 lg:px-28 py-4 bg-[#043873]">
        <nav className="flex flex-row items-center justify-between w-full z-100">
          <a className="inline-flex justify-start items-center gap-[8px] lg:w-[20%]" href='/'>
            <div className="justify-start text-white text-[32px] font-[700] leading-[40px] tracking-[0.048px]">CRITERIAN</div>
            <img className="w-14 h-14" src={logo} />
          </a>
          <button onClick={() => { setMenuOpen(!menuOpen) }} className=" lg:hidden text-white text-3xl mr-[1%] "><img src={menu}/></button>
          {menuOpen && (
            <div className="fixed top-0 left-0 right-0 mt-[70px] bg-[#043873] flex flex-col items-center justify-start space-y-[32px] text-white text-xl h-auto max-h-fit py-6">
              <div className='w-full place-items-center'>
                <NavbarLinks color='white' variant="menu" onClick={() => { setMenuOpen(false) }} />
              </div>
              <img src={language} onClick={() => { void onChangeLang(isSpanish ? 'en' : 'es') }} alt="Language" className="w-[40px] h-[40px] cursor-pointer" />
              <BotonContactoResponsive texto='CONTACTO' onClick={() => { setMenuOpen(false) }} />
            </div>
          )}
          <div className="hidden lg:flex whitespace-nowrap lg:justify-center lg:w-[60%]">
            <NavbarLinks color='white' variant="header" />
          </div>
          <div className="hidden lg:flex lg:items-center lg:justify-center lg:w-[20%]">
            <BotonContacto texto='CONTACTO' />
          </div>
        </nav>
        <div className="hidden lg:block absolute right-12 top-1/2 transform -translate-y-1/2">
          <img src={language} onClick={() => { void onChangeLang(isSpanish ? 'en' : 'es') }} alt="Language" className="w-[40px] h-[40px] aspect-square object-contain cursor-pointer" />
        </div>
      </div>

      {/* Background div que va al fondo */}
      <div className="fixed top-0 left-0 right-0 bg-[#043873] w-screen xl:overflow-x-auto py-4 px-[233px] z-30 h-[105px]"></div>
    </div>
  )
}

export default Header
