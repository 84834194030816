/* eslint-disable react/react-in-jsx-scope */
/* import BienvenidaCriterian from './bienvenida' */
import ImagenesEmpresa from './imagenes-empresa'
import Descripciones from '../../shared/descriptions/Descripciones'
import FraseEmpresa from './frase-empresa'
import NuestraTecnologia from './nuestra-tecnologia'
import Invitacion from '../empresa/invitacion-criterian'
import fondoBanner from '../../../assets/images/empresa/Hero-section.png'
import React from 'react'
import constantes from '../../constantes/constantes'
import Banner from '../../shared/frontBanner/banner'
const valores = constantes.valores
const LaEmpresa = (): any => {
  return (
      <div className='w-full h-full flex flex-col justify-center items-center '>
       <Banner image={fondoBanner} height='80vh' title='Bienvenidos a Criterian' description='En el vertiginoso mundo del desarrollo de software, abrazamos la incertidumbre como una oportunidad para innovar. Cada proyecto es un desafío que aceptamos con valentía, sabiendo que en la complejidad encontramos nuestra fuerza. No nos detenemos ante lo desconocido; lo abrazamos y lo convertimos en nuestra ventaja.'></Banner>
       <ImagenesEmpresa/>
       <Descripciones items={valores} titulo='Nuestros valores' subrayado='valores' blanco={false}/>
       <FraseEmpresa/>
       <NuestraTecnologia/>
       <Invitacion/>
      </div>
  )
}
export default LaEmpresa
