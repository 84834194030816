import React from 'react'
import constantes from '../../constantes/constantes'
import Text from '../../shared/textStyleDefault/texto'
const listaBeneficios = constantes.beneficios
const Beneficios = (): any => {
  return (
    <div className="flex flex-row max-w-[1200px] mx-auto gap-10">
        {listaBeneficios.map((beneficio) => (
          <div key={beneficio.icono} className=" inline-flex flex-col items-start  text-start min-w-[260px] max-w-[270px] gap-6 flex-1">
            <div>
              <img src={beneficio.icono} alt="icono" className="size-[64px]" />
            </div>
            <div className='gap-4'>
             <Text as='h5' className='mb-[20px]'>{beneficio.titulo}</Text>
             <Text as='p' variant='subtitle1'>{beneficio.texto}</Text>
            </div>
          </div>
        ))}
    </div>
  )
}
export default Beneficios
