import facebookIcon from '../../../assets/images/footer/facebook.svg'
import twitterIcon from '../../../assets/images/footer/twitter.svg'
import linkedinIcon from '../../../assets/images/footer/linkedin.svg'
import Text from '../textStyleDefault/texto'
import React from 'react'
const SocialMedia = (): any => {
  return (
    <div className='w-[400px] h-[24px] flex items-center flex-row md:justify-end  gap-[22px]'>
      <Text variant='subtitle1' className='text-gray-500 !text-[12px] md:!text-[14px]'>Social Media </Text>
      <a href="https://twitter.com/Criterian_Softw/" target="_blank" rel="noreferrer"><img decoding="async" src={twitterIcon} alt="twitter" width="25" height="25" /></a>
      <a href="https://www.linkedin.com/in/criterian-software-305241135" target="_blank" rel="noreferrer"><img decoding="async" src={linkedinIcon} alt="linkedin" width="25" height="25" /></a>
      <a href="https://www.facebook.com/criteriansoftware/" target="_blank" rel="noreferrer" ><img decoding="async" src={facebookIcon} alt="facebook" width="25" height="25" /></a>
    </div>
  )
}
export default SocialMedia
